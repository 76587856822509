import * as React from 'react'
import {Layer, Source, MapboxMap, MapRef} from 'react-map-gl'

import {
  Stack,
  Box,
  Typography,
  Paper,
  IconButton,
  Switch,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
} from '@mui/material'
import chroma from 'chroma-js'

import {AdmittedUserContainer} from 'src/components/copilot/AdmittedUserContainer'
import {RadioFilterInput} from 'src/components/copilot/RadioFilterInput'
import {SelectFilterInput} from 'src/components/copilot/SelectFilterInput'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {MobileSwipeDrawer} from 'src/framework/ui/MobileSwipeDrawer'
import {apiCoPilotParcelsNearbyBusinessesPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {Parcel} from 'src/types/copilot'
import {trackEvent} from 'src/util/analytics'

export const STEPS = 5 // Subtract 1 since we duplicate the last bucket

type MinMax = {
  min: number
  max: number
}
export type DemographicOverlayMinMax = Record<DemographicOverlay, MinMax>

type DemographicOverlayLegendLabel = {
  label: string
  // TODO: how to deal with singulars?
  unit: string
  colorExpression: ReturnType<typeof generateFillColorExpression>
  prefix?: boolean
  formatter: (value: number) => string
}

export type DemographicOverlayRecord = Record<
  DemographicOverlay,
  DemographicOverlayLegendLabel
>

export const DEMOGRAPHICS_SOURCE = 'demographics'
export const REAL_ESTATE_SOURCE = 'home_value_change'

function toFixedN(precision: number) {
  return (value: number): string => {
    return Number(value.toFixed(precision)).toLocaleString()
  }
}

function ratioToPercent(ratio: number): string {
  return Number((ratio * 100).toFixed(1)).toLocaleString()
}

function ratioToPercentAnnualized(ratio: number): string {
  const years = new Date().getFullYear() - 2010 // TODO: hard coded
  const cagr =
    years === 0 || ratio === 0 ? ratio : Math.pow(ratio, 1 / years) - 1
  return Number((cagr * 100).toFixed(1)).toLocaleString()
}

type DemographicOverlayConfig = {
  readonly label: string
  readonly unit: string
  readonly color: keyof typeof chroma.brewer
  readonly formatter: (value: number) => string
  readonly prefix?: boolean
  readonly steps?: number
}

export const DEMOGRAPHIC_OVERLAYS: Record<string, DemographicOverlayConfig> = {
  population_count_change_ratio_since_2010: {
    label: 'Annual Population Change since 2010',
    unit: '%',
    color: 'Blues',
    formatter: ratioToPercentAnnualized,
  },
  labor_labor_force_unemployment_rate_total: {
    label: 'Unemployment Rate',
    unit: '%',
    color: 'Reds',
    formatter: toFixedN(2),
  },
  education_ratio_college_educated: {
    label: 'Percent College Educated',
    unit: '%',
    color: 'Oranges',
    formatter: ratioToPercent,
  },
  household_median_income_total: {
    label: 'Median Household Income',
    unit: '$',
    prefix: true,
    color: 'Greens',
    steps: 6,
    formatter: toFixedN(0),
  },
  household_average_size_total: {
    label: 'Average Household Size',
    unit: ' people',
    color: 'Purples',
    formatter: toFixedN(2),
  },
} as const

export type DemographicOverlay = keyof typeof DEMOGRAPHIC_OVERLAYS

export function initializeDemographicOverlayRecord(
  map: MapboxMap,
  steps: number,
) {
  const newRecord = {} as DemographicOverlayRecord
  const features = map.querySourceFeatures('composite', {
    sourceLayer: DEMOGRAPHICS_SOURCE,
  })

  ;(Object.keys(DEMOGRAPHIC_OVERLAYS) as DemographicOverlay[]).forEach(
    (overlay) => {
      const overlayConfig = DEMOGRAPHIC_OVERLAYS[overlay]
      const buckets = calculateBuckets(
        features,
        overlay,
        overlayConfig.steps ?? STEPS,
      )
      if (!buckets) {
        newRecord[overlay] = {
          colorExpression: null,
          ...overlayConfig,
        }
        return
      }

      const colorExpression = generateFillColorExpression(
        buckets,
        overlay,
        overlayConfig.color,
        steps,
      )
      newRecord[overlay] = {
        colorExpression,
        ...overlayConfig,
      }
    },
  )
  return newRecord
}

function calculateBuckets(
  features: GeoJSON.Feature[],
  property: string,
  steps: number,
): number[] | null {
  // Filter out null values and get sorted values
  const values = features
    .map((f) => f.properties?.[property])
    .filter((v): v is number => v != null)
    .sort((a, b) => a - b)

  if (values.length === 0) return null

  // Calculate quartiles for outlier detection
  const q1Index = Math.floor(values.length * 0.25)
  const q3Index = Math.floor(values.length * 0.75)
  const q1 = values[q1Index]
  const q3 = values[q3Index]
  const iqr = q3 - q1

  const lowerBound = q1 - 1.5 * iqr
  const upperBound = q3 + 1.5 * iqr

  const filteredValues = values.filter(
    (value) => value >= lowerBound && value <= upperBound,
  )

  if (filteredValues.length === 0) return null

  // Calculate evenly spaced steps from filtered min to max
  const min = filteredValues[0]
  const max = filteredValues[filteredValues.length - 1]
  const bucketSize = (max - min) / (steps - 1)

  return Array.from({length: steps}, (_, i) => min + bucketSize * i)
}

export function generateFillColorExpression(
  buckets: number[],
  property: DemographicOverlay,
  colorScale: keyof typeof chroma.brewer,
  steps: number,
): [string, [string, string], ...(string | number)[]] | null {
  if (!buckets) {
    return null
  }

  const colors = chroma.scale(colorScale).colors(steps)

  const expression: [string, [string, string], ...(string | number)[]] = [
    'step',
    ['get', property],
  ]

  // Skip min and max values since they're handled by the default colors
  buckets.slice(1, -1).forEach((value, index) => {
    expression.push(colors[index])
    expression.push(value)
  })

  // Add final color
  expression.push(colors[colors.length - 1])

  return expression
}

type DemographicOverlayProps = {
  overlay: DemographicOverlay | 'none'
  overlayRecord: DemographicOverlayRecord
}

export function DemographicOverlay({
  overlay,
  overlayRecord,
}: DemographicOverlayProps) {
  return (
    <>
      {(Object.keys(DEMOGRAPHIC_OVERLAYS) as DemographicOverlay[]).map(
        (layer) => {
          const label = overlayRecord[layer]
          if (!label || !label.colorExpression) {
            return null
          }
          return (
            <Layer
              key={layer}
              id={layer}
              type="fill"
              source="composite"
              source-layer={DEMOGRAPHICS_SOURCE}
              layout={{visibility: overlay === layer ? 'visible' : 'none'}}
              paint={{
                'fill-color': label.colorExpression,
                'fill-opacity': 0.4,
              }}
              beforeId="neighborhoods"
            />
          )
        },
      )}
    </>
  )
}

const setOpacity = (hex: string, alpha: number) =>
  `${hex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, '0')}`

type LegendItem = {
  color: string
  value: number
}

function colorsToLegend(
  expression: [string, [string, string], ...(string | number)[]],
): LegendItem[] {
  // The expression format is: ['step', ['get', property], color1, value1, color2, value2, ..., colorN]
  // We want to skip the first two elements: ['step', ['get', property]]
  const items = expression.slice(2)

  const legend: LegendItem[] = []

  // Process pairs of color and value, except for the last color
  for (let i = 0; i < items.length - 1; i += 2) {
    const color = items[i] as string
    const value = items[i + 1] as number
    legend.push({color, value})
  }

  // Add the final color with the last value
  if (legend.length > 0) {
    legend.push({
      color: items[items.length - 1] as string,
      value: legend[legend.length - 1].value,
    })
  }

  return legend
}

export type ActiveOverlay =
  | {type: 'demographic'; overlay: DemographicOverlay}
  | {type: 'realEstate'; overlay: RealEstateOverlay}
  | {type: 'none'}

type Toggle = boolean

export type AllOverlaysSettings = {
  activeOverlay: ActiveOverlay
  traffic: Toggle
  satellite: Toggle
  nearbyBusinesses: BusinessCategory[]
}

type OverlayLegendProps = {
  activeOverlay: ActiveOverlay
  demographicOverlayRecord: DemographicOverlayRecord
  realEstateOverlayRecord: RealEstateOverlayRecord
}

export function OverlayLegend({
  activeOverlay,
  demographicOverlayRecord,
  realEstateOverlayRecord,
}: OverlayLegendProps) {
  if (activeOverlay.type === 'none') {
    return null
  }

  const record =
    activeOverlay.type === 'demographic'
      ? demographicOverlayRecord
      : realEstateOverlayRecord
  const overlay = activeOverlay.overlay
  const label = record[overlay]

  if (!label.colorExpression) {
    return (
      <Stack
        padding={1}
        direction="column"
        gap={1}
        fontSize="12px"
        sx={{backgroundColor: '#FFFFFF', borderRadius: '2px'}}
      >
        <Typography>{label.label}</Typography>
        <Typography>No Data</Typography>
      </Stack>
    )
  }

  const legendItems = colorsToLegend(label.colorExpression)
  return (
    <Stack
      direction="column"
      gap={1}
      fontSize="12px"
      sx={{backgroundColor: '#FFFFFF', borderRadius: '2px'}}
    >
      <Typography>{label.label}</Typography>
      {legendItems.map(({color, value}, idx) => {
        return (
          <Stack
            key={color + value}
            direction="row"
            gap={1}
            alignItems="center"
            maxWidth="100%"
            sx={{breakInside: 'avoid'}}
          >
            <Box
              sx={{
                width: '18px',
                height: '18px',
                backgroundColor: setOpacity(color, 0.7),
                borderRadius: 1,
                border: '1px solid #000',
              }}
            ></Box>
            <div>
              {idx === 0 ? '< ' : idx === legendItems.length - 1 ? '> ' : ''}
              {label.prefix ? label.unit : null}
              {label.formatter(value)}
              {label.prefix ? null : label.unit}
            </div>
          </Stack>
        )
      })}
    </Stack>
  )
}

type OverlayControlsButtonProps = {
  initialOverlays: AllOverlaysSettings
  applyOverlays: (newOverlays: AllOverlaysSettings) => void
  withNearbyBusinesses?: boolean
}

export const OverlayControlsButton = ({
  initialOverlays,
  applyOverlays,
  withNearbyBusinesses,
}: OverlayControlsButtonProps): JSX.Element => {
  const [renderDrawer, setRenderDrawer] = React.useState(false)

  const handleClick = React.useCallback(
    (e) => {
      e.stopPropagation()
      trackEvent(`Open ${withNearbyBusinesses ? 'Property Map ' : ''}Overlays`)
      setRenderDrawer(true)
    },
    [withNearbyBusinesses],
  )

  return (
    <>
      <AdmittedUserContainer
        attemptedActionName="openOverlays"
        onCreateAccount={() => setRenderDrawer(true)}
      >
        <IconButton
          color="secondary"
          sx={{
            backgroundColor: '#fff',
            aspectRatio: '1 / 1',
            height: '46px',
            width: '46px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'rgba(0,0,0,0.23)', // TODO: use theme
            position: 'relative',
          }}
          onClick={handleClick}
        >
          <MaterialSymbolIcon>layers</MaterialSymbolIcon>
        </IconButton>
      </AdmittedUserContainer>
      {renderDrawer && (
        <OverlaysDrawer
          initialOverlays={initialOverlays}
          onClose={() => setRenderDrawer(false)}
          applyOverlays={applyOverlays}
          withNearbyBusinesses={withNearbyBusinesses}
        />
      )}
    </>
  )
}

function haveDifferentValues<T extends Record<string, unknown>>(
  obj1: T,
  obj2: T,
): boolean {
  function isDifferent(val1: unknown, val2: unknown): boolean {
    if (
      typeof val1 === 'object' &&
      typeof val2 === 'object' &&
      val1 !== null &&
      val2 !== null
    ) {
      // Recursively compare objects
      return haveDifferentValues(
        val1 as Record<string, unknown>,
        val2 as Record<string, unknown>,
      )
    }
    // Compare primitive values
    return val1 !== val2
  }

  const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)])

  for (const key of allKeys) {
    if (key in obj1 && key in obj2) {
      if (isDifferent(obj1[key as keyof T], obj2[key as keyof T])) {
        return true
      }
    }
  }
  return false
}

type OverlaysDrawerProps = {
  initialOverlays: AllOverlaysSettings
  onClose: () => void
  applyOverlays: (overlays: AllOverlaysSettings) => void
  withNearbyBusinesses?: boolean
}

export const OverlaysDrawer = ({
  initialOverlays,
  onClose,
  applyOverlays,
  withNearbyBusinesses,
}: OverlaysDrawerProps): JSX.Element => {
  const [currentOverlays, setCurrentOverlays] =
    React.useState<AllOverlaysSettings>(initialOverlays)
  return (
    <MobileSwipeDrawer
      open={true}
      onClose={() => {
        if (haveDifferentValues(initialOverlays, currentOverlays)) {
          trackEvent('Apply Overlays')
        } else {
          trackEvent('Close Overlays')
        }
        applyOverlays(currentOverlays)
        onClose()
      }}
      onClick={(e) => e.stopPropagation()}
      title={'Overlays'}
      big={true}
    >
      <Box px={2} py={2}>
        <OverlaysForm
          overlays={currentOverlays}
          setCurrentOverlays={setCurrentOverlays}
          withNearbyBusinesses={withNearbyBusinesses}
        />
      </Box>
    </MobileSwipeDrawer>
  )
}

type OverlayControlProps = {
  label: string
  children: React.ReactNode
}
const OverlayControl = ({label, children}: OverlayControlProps) => {
  return (
    <Accordion
      defaultExpanded
      disableGutters
      sx={{border: 'none', '&::before': {display: 'none'}, paddingX: 0}}
    >
      <AccordionSummary
        expandIcon={<MaterialSymbolIcon>expand_more</MaterialSymbolIcon>}
        sx={{paddingX: 0}}
      >
        <Typography variant="h2">{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {' '}
        <Stack direction="column" spacing={2} alignItems="flex-start">
          {children}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

type OverlaysFormProps = {
  overlays: AllOverlaysSettings
  setCurrentOverlays: (overlays: AllOverlaysSettings) => void
  withNearbyBusinesses?: boolean
}

const OverlaysForm: React.FC<OverlaysFormProps> = ({
  overlays,
  setCurrentOverlays,
  withNearbyBusinesses,
}) => {
  const updateOverlay = <K extends keyof AllOverlaysSettings>(
    key: K,
    value: AllOverlaysSettings[K],
  ) => {
    trackEvent(`Set ${withNearbyBusinesses ? 'Property Map ' : ''}Overlay`, {
      overlay: key,
      value,
    })
    setCurrentOverlays({
      ...overlays,
      [key]: value,
    })
  }

  return (
    <Paper
      sx={{width: '100%', maxWidth: 600, mx: 'auto', p: 2, border: 'none'}}
    >
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        minHeight="48px"
      >
        <Typography variant="h2">Satellite</Typography>
        <Switch
          checked={overlays.satellite}
          onChange={(e) => updateOverlay('satellite', e.target.checked)}
          inputProps={{'aria-label': 'controlled'}}
        />
      </Stack>
      <Divider />

      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        minHeight="48px"
      >
        <Typography variant="h2">Traffic</Typography>
        <Switch
          checked={overlays.traffic}
          onChange={(e) => updateOverlay('traffic', e.target.checked)}
          inputProps={{'aria-label': 'controlled'}}
        />
      </Stack>
      <Divider />

      <OverlayControl label="Demographics">
        <RadioFilterInput<DemographicOverlay | 'none'>
          value={
            overlays.activeOverlay.type === 'demographic'
              ? overlays.activeOverlay.overlay
              : 'none'
          }
          onChange={(value) =>
            updateOverlay(
              'activeOverlay',
              value === 'none'
                ? {type: 'none'}
                : {type: 'demographic', overlay: value},
            )
          }
          options={[
            {label: 'None', value: 'none'},
            ...(
              Object.entries(DEMOGRAPHIC_OVERLAYS) as [
                keyof typeof DEMOGRAPHIC_OVERLAYS,
                {
                  label: string
                  unit: string
                  color: string
                  formatter: (val: number) => string
                },
              ][]
            ).map(([overlay, label]) => {
              return {
                label: label.label,
                value: overlay,
              }
            }),
          ]}
        />
      </OverlayControl>
      <Divider />

      <OverlayControl label="Real Estate">
        <RadioFilterInput<RealEstateOverlay | 'none'>
          value={
            overlays.activeOverlay.type === 'realEstate'
              ? overlays.activeOverlay.overlay
              : 'none'
          }
          onChange={(value) =>
            updateOverlay(
              'activeOverlay',
              value === 'none'
                ? {type: 'none'}
                : {type: 'realEstate', overlay: value},
            )
          }
          options={[
            {label: 'None', value: 'none'},
            ...(
              Object.entries(REAL_ESTATE_OVERLAYS) as [
                keyof typeof REAL_ESTATE_OVERLAYS,
                {
                  label: string
                  unit: string
                  color: string
                  formatter: (val: number) => string
                },
              ][]
            ).map(([overlay, label]) => {
              return {
                label: label.label,
                value: overlay,
              }
            }),
          ]}
        />
      </OverlayControl>
      <Divider />

      {withNearbyBusinesses && (
        <OverlayControl label="Nearby Businesses">
          <SelectFilterInput<BusinessCategory>
            selections={new Set(overlays.nearbyBusinesses)}
            onChange={(value) =>
              updateOverlay('nearbyBusinesses', value ? [...value] : [])
            }
            options={[
              {value: 'accommodation', label: 'Accommodation'},
              {value: 'agriculture', label: 'Agriculture'},
              {value: 'automotive', label: 'Automotive'},
              {
                value: 'building & home services',
                label: 'Building & Home Services',
              },
              {value: 'child care', label: 'Child Care'},
              {value: 'education', label: 'Education'},
              {value: 'fitness & recreation', label: 'Fitness & Recreation'},
              {value: 'food & beverage', label: 'Food & Beverage'},
              {value: 'grocery & convenience', label: 'Grocery & Convenience'},
              {value: 'heavy industrial', label: 'Heavy Industrial'},
              {value: 'light industrial', label: 'Light Industrial'},
              {value: 'local services', label: 'Local Services'},
              {value: 'medical', label: 'Medical'},
              {value: 'personal care', label: 'Personal Care'},
              {value: 'pet care', label: 'Pet Care'},
              {value: 'professional services', label: 'Professional Services'},
              {value: 'public sector', label: 'Public Sector'},
              {value: 'retail', label: 'Retail'},
            ]}
          />
        </OverlayControl>
      )}
    </Paper>
  )
}

type RealEstateOverlayConfig = {
  readonly label: string
  readonly unit: string
  readonly color: keyof typeof chroma.brewer
  readonly formatter: (value: number) => string
  readonly prefix?: boolean
  readonly steps?: number
}

export const REAL_ESTATE_OVERLAYS: Record<string, RealEstateOverlayConfig> = {
  zhvi_pct_change: {
    label: 'Percent Home Value Change since 2014',
    unit: '%',
    color: 'Blues',
    formatter: ratioToPercent,
  },
} as const

export type RealEstateOverlay = keyof typeof REAL_ESTATE_OVERLAYS

export type RealEstateOverlayMinMax = Record<RealEstateOverlay, MinMax>

export type RealEstateOverlayRecord = Record<
  RealEstateOverlay,
  DemographicOverlayLegendLabel
>

export function initializeRealEstateOverlayRecord(
  map: MapboxMap,
  steps: number,
) {
  const newRecord = {} as RealEstateOverlayRecord
  const features = map.querySourceFeatures('composite', {
    sourceLayer: REAL_ESTATE_SOURCE,
  })

  ;(Object.keys(REAL_ESTATE_OVERLAYS) as RealEstateOverlay[]).forEach(
    (overlay) => {
      const overlayConfig = REAL_ESTATE_OVERLAYS[overlay]
      const buckets = calculateBuckets(
        features,
        overlay,
        overlayConfig.steps ?? STEPS,
      )
      if (!buckets) {
        newRecord[overlay] = {
          colorExpression: null,
          ...overlayConfig,
        }
        return
      }

      const colorExpression = generateFillColorExpression(
        buckets,
        overlay,
        overlayConfig.color,
        steps,
      )
      newRecord[overlay] = {
        colorExpression,
        ...overlayConfig,
      }
    },
  )
  return newRecord
}

type RealEstateOverlayProps = {
  overlay: RealEstateOverlay | 'none'
  overlayRecord: RealEstateOverlayRecord
}

export function RealEstateOverlay({
  overlay,
  overlayRecord,
}: RealEstateOverlayProps) {
  return (
    <>
      {(Object.keys(REAL_ESTATE_OVERLAYS) as RealEstateOverlay[]).map(
        (layer) => {
          const label = overlayRecord[layer]
          if (!label || !label.colorExpression) {
            return null
          }
          return (
            <Layer
              key={layer}
              id={layer}
              type="fill"
              source="composite"
              source-layer={REAL_ESTATE_SOURCE}
              layout={{visibility: overlay === layer ? 'visible' : 'none'}}
              paint={{
                'fill-color': label.colorExpression,
                'fill-opacity': 0.4,
              }}
              beforeId="traffic"
            />
          )
        },
      )}
    </>
  )
}

type TrafficProps = {
  active: boolean
  mapRef: React.RefObject<MapRef>
}

export function TrafficLayer({active, mapRef}: TrafficProps) {
  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }
    const map = mapRef.current.getMap()

    if (map.getLayer('traffic')) {
      map.setLayoutProperty(
        'traffic',
        'visibility',
        active ? 'visible' : 'none',
      )
    }
  }, [active, mapRef])

  return null
}

// TODO: hard coded
export function TrafficLegend() {
  return (
    <Stack
      direction="column"
      gap={1}
      fontSize="12px"
      sx={{backgroundColor: '#FFFFFF', borderRadius: '2px'}}
    >
      <Typography>Avg Daily Traffic</Typography>
      <Stack direction="row" gap="1" maxHeight="100%">
        <Box
          height="100px"
          width="20px"
          position="relative"
          sx={{
            background: `linear-gradient(
                      to bottom,
                      hsl(57, 100%, 81%) 0%,
                      hsl(0, 100%, 48%) 100%
                    )`,
            border: '1px solid #ccc',
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: '10px',
            fontSize: '12px',
            height: '100px',
          }}
        >
          {' '}
          <div>10 cars</div>
          <div>200k cars</div>
        </Box>
      </Stack>
    </Stack>
  )
}

type SatelliteProps = {
  active: boolean
  mapRef: React.RefObject<MapRef>
}

export function SatelliteLayer({active, mapRef}: SatelliteProps) {
  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }
    const map = mapRef.current.getMap()

    if (map.getLayer('satellite')) {
      map.setLayoutProperty(
        'satellite',
        'visibility',
        active ? 'visible' : 'none',
      )
    }
  }, [active, mapRef])

  return null
}

type BusinessCategory =
  | 'accommodation'
  | 'agriculture'
  | 'automotive'
  | 'building & home services'
  | 'child care'
  | 'education'
  | 'fitness & recreation'
  | 'food & beverage'
  | 'grocery & convenience'
  | 'heavy industrial'
  | 'light industrial'
  | 'local services'
  | 'medical'
  | 'personal care'
  | 'pet care'
  | 'professional services'
  | 'public sector'
  | 'retail'

type NearbyBusiness = {
  displayName: string
  address: string
  taxAssessorId: string
  location: GeoJSON.Point
}

type NearbyBusinessesProps = {
  categories: BusinessCategory[]
  parcel: Parcel
}

export function NearbyBusinessesLayer({
  parcel,
  categories,
}: NearbyBusinessesProps) {
  const {request, response, completed} = useRequest<
    NearbyBusiness[],
    void,
    {taxAssessorId: string; categories: string}
  >('GET', apiCoPilotParcelsNearbyBusinessesPath())
  const theme = useTheme()
  const active = React.useMemo(() => categories.length >= 1, [categories])

  React.useEffect(() => {
    if (active) {
      request({
        params: {
          taxAssessorId: parcel.taxAssessorId,
          categories: categories.join(','),
        },
      })
    }
  }, [parcel, categories, request, active])

  const asGeoJSON: GeoJSON.FeatureCollection | null = React.useMemo(() => {
    if (response === null) {
      return null
    }

    if (response.length === 0) {
      // TODO: pre-load all categories and do client side filtering in this component
      console.log('no nearby businesses')
    }
    // TODO: need to adjust zoom of map to account for farther nearby businesses?
    // maybe can actually just set default zoom to account for that distance?
    return {
      type: 'FeatureCollection',
      features: response.map((nb) => {
        return {
          type: 'Feature',
          geometry: nb.location,
          properties: {
            displayName: nb.displayName,
            taxAssessorId: nb.taxAssessorId,
            address: nb.address,
          },
        }
      }),
    }
  }, [response])

  if (categories.length === 0 || !active || !completed || asGeoJSON === null) {
    return null
  }

  return (
    <Source
      key="nearby-businesses"
      id="nearby-businesses"
      type="geojson"
      data={asGeoJSON}
    >
      <Layer
        key="nearby-businesses-layer"
        id="nearby-businesses-layer"
        source="nearby-businesses"
        type="circle"
        layout={{
          visibility: active ? 'visible' : 'none',
        }}
        // TODO: nicer paint settings
        paint={{
          'circle-color': '#FFFFFF',
          'circle-radius': 10,
          'circle-stroke-color': theme.palette.primary.main,
          'circle-stroke-width': 2,
          'circle-opacity': 1,
          'circle-stroke-opacity': 1,
        }}
      ></Layer>
    </Source>
  )
}
