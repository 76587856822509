/* eslint-disable react/no-unused-prop-types */
import * as React from 'react'
import {Map, Layer, Marker, MapEvent} from 'react-map-gl'
import type {MapRef} from 'react-map-gl'

import {Box, useTheme} from '@mui/material'

import {
  OverlayControlsButton,
  TrafficLayer,
  SatelliteLayer,
  NearbyBusinessesLayer,
  DemographicOverlayRecord,
  STEPS,
  initializeDemographicOverlayRecord,
  DemographicOverlay,
  AllOverlaysSettings,
  OverlayLegend,
  RealEstateOverlay,
  RealEstateOverlayRecord,
  initializeRealEstateOverlayRecord,
} from 'src/components/copilot/mapbox/Overlays'
import {RALEIGH_CAMERA_POSITION} from 'src/components/copilot/mapbox/SearchMapV2'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {Parcel} from 'src/types/copilot'

const STYLE_URL = 'mapbox://styles/withcompany/cm1uvw6lq007r01pdegqxa02x'
const PARCEL_LAYER_ID = 'parcels'

interface Props {
  parcel: Parcel
  startingZoom?: number
  enableOverlays?: boolean
}

export const ParcelMap = ({
  parcel,
  startingZoom = 13,
  enableOverlays = true,
}: Props): JSX.Element | null => {
  const publicToken = React.useRef(
    document.querySelector<HTMLMetaElement>('meta[name="mapbox"]')?.content,
  )
  const mapRef = React.useRef<MapRef | null>(null)

  const [viewState, setViewState] = React.useState(
    parcel.parcelCentroid
      ? {
          longitude: parcel.parcelCentroid?.coordinates[0],
          latitude: parcel.parcelCentroid?.coordinates[1],
          zoom: startingZoom,
        }
      : RALEIGH_CAMERA_POSITION,
  )

  const [overlays, setOverlays] = React.useState<AllOverlaysSettings>({
    nearbyBusinesses: [],
    traffic: false,
    activeOverlay: {type: 'none'},
    satellite: false,
  })
  const [demographicOverlayRecord, setdemographicOverlayRecord] =
    React.useState<DemographicOverlayRecord | null>(null)
  const [realEstateOverlayRecord, setRealEstateOverlayRecord] =
    React.useState<RealEstateOverlayRecord | null>(null)

  const theme = useTheme()

  const onLoad = React.useCallback(
    (event: MapEvent) => {
      if (!demographicOverlayRecord) {
        const newRecord = initializeDemographicOverlayRecord(
          event.target,
          STEPS,
        )
        setdemographicOverlayRecord(newRecord)
      }
      if (!realEstateOverlayRecord) {
        const newRecord = initializeRealEstateOverlayRecord(event.target, STEPS)
        setRealEstateOverlayRecord(newRecord)
      }
    },
    [demographicOverlayRecord, realEstateOverlayRecord],
  )

  const parcelPaint = React.useMemo(() => {
    return {
      'fill-color': [
        'case',
        ['==', ['get', 'cherre_parcel_id'], parcel.cherreParcelId],
        theme.palette.primary.main,
        'transparent',
      ],
    }
  }, [parcel, theme])

  return (
    <Box height="100%" width="100%" position="relative">
      {enableOverlays && (
        <Box position="absolute" top="2%" right="2%" zIndex={1000}>
          <OverlayControlsButton
            initialOverlays={overlays}
            applyOverlays={setOverlays}
            withNearbyBusinesses={true}
          />
        </Box>
      )}
      <Map
        {...viewState}
        ref={mapRef}
        initialViewState={{
          longitude: parcel.parcelCentroid?.coordinates[0],
          latitude: parcel.parcelCentroid?.coordinates[1],
          zoom: startingZoom,
        }}
        mapLib={window.mapboxgl}
        mapboxAccessToken={publicToken.current}
        mapStyle={STYLE_URL}
        fadeDuration={0}
        onLoad={onLoad}
        onMove={(event) => setViewState(event.viewState)}
        style={{height: '400px', width: '100%', position: 'relative'}}
      >
        <Layer
          key={PARCEL_LAYER_ID}
          id={PARCEL_LAYER_ID}
          type="fill"
          // @ts-expect-error TODO: wtf is wrong with this type
          paint={parcelPaint}
        />
        <ParcelMarker parcel={parcel} />
        {demographicOverlayRecord &&
          overlays.activeOverlay.type === 'demographic' && (
            <DemographicOverlay
              overlay={overlays.activeOverlay.overlay}
              overlayRecord={demographicOverlayRecord}
            />
          )}
        {realEstateOverlayRecord &&
          overlays.activeOverlay.type === 'realEstate' && (
            <RealEstateOverlay
              overlay={overlays.activeOverlay.overlay}
              overlayRecord={realEstateOverlayRecord}
            />
          )}
        <TrafficLayer active={overlays.traffic} mapRef={mapRef} />
        <SatelliteLayer active={overlays.satellite} mapRef={mapRef} />
        <NearbyBusinessesLayer
          categories={overlays.nearbyBusinesses}
          parcel={parcel}
        />
      </Map>
      {overlays.activeOverlay.type !== 'none' &&
      demographicOverlayRecord &&
      realEstateOverlayRecord ? (
        <LegendContainer>
          <OverlayLegend
            activeOverlay={overlays.activeOverlay}
            demographicOverlayRecord={demographicOverlayRecord}
            realEstateOverlayRecord={realEstateOverlayRecord}
          />
        </LegendContainer>
      ) : null}
    </Box>
  )
}

const ParcelMarker = ({parcel}: {parcel: Parcel}) => {
  return (
    <Marker
      // TODO: what do if the centroid is missing?
      longitude={parcel.parcelCentroid?.coordinates[0] ?? 0}
      latitude={parcel.parcelCentroid?.coordinates[1] ?? 0}
      key={parcel.cherreParcelId}
    >
      <Box display="flex" alignItems="center">
        <MaterialSymbolIcon fontSize="large" sx={{color: '#006AFF'}}>
          pin_drop
        </MaterialSymbolIcon>
      </Box>
    </Marker>
  )
}

function LegendContainer({
  children,
  right,
}: {
  children: JSX.Element
  right?: boolean
}) {
  return (
    <Box
      padding={1}
      maxWidth="50%"
      maxHeight="40%"
      sx={{
        position: 'absolute',
        bottom: '4%',
        left: right ? undefined : '3%',
        right: right ? '3%' : undefined,
        zIndex: 2,
        backgroundColor: '#FFFFFF',
        border: '2px solid black',
      }}
    >
      {children}
    </Box>
  )
}
