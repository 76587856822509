import * as React from 'react'
import {Map, Source, Layer} from 'react-map-gl'
import type {
  ViewStateChangeEvent,
  MapEvent,
  MapMouseEvent,
  MapRef,
  LngLatBoundsLike,
} from 'react-map-gl'

import {Box, BoxProps, CircularProgress, useTheme} from '@mui/material'
import {bboxPolygon} from '@turf/bbox-polygon'
import {booleanContains} from '@turf/boolean-contains'
import {ExpressionSpecification, GeoJSONSource} from 'mapbox-gl'

import {Filter} from 'src/components/copilot/AdvancedMapFilters'
import {
  DemographicOverlay,
  DemographicOverlayRecord,
  OverlayLegend,
  initializeDemographicOverlayRecord,
  AllOverlaysSettings,
  TrafficLayer,
  SatelliteLayer,
  TrafficLegend,
  STEPS,
  RealEstateOverlay,
  RealEstateOverlayRecord,
  initializeRealEstateOverlayRecord,
} from 'src/components/copilot/mapbox/Overlays'
import {
  getSourcePayload,
  sourceIdToPayload,
  payloadToSourceId,
  clusterLayer,
  countsLayer,
  unclusteredLayer,
  targetsLayer,
  SourceRequestPayload,
  portfolioLayer,
  claimedLayer,
  PLAIN_PILL_COLOR,
} from 'src/components/copilot/mapbox/mapUtils'
import {apiCoPilotParcelsFilterPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {useWatchlistParcels} from 'src/hooks/request/useWatchlistParcels'
import {ClaimedLocation, Parcel} from 'src/types/copilot'

const STYLE_URL = 'mapbox://styles/withcompany/cm1uvw6lq007r01pdegqxa02x'
const PARCEL_LAYER_ID = 'parcels'
const NEIGHBORHOOD_LAYER_ID = 'zipcodes'
export const RALEIGH_CAMERA_POSITION = {
  latitude: 35.7741,
  longitude: -78.6396,
  zoom: 12,
}

type CameraPosition = {
  latitude: number
  longitude: number
  zoom: number
}

type CameraBbox = LngLatBoundsLike

export type CameraSetting =
  | {
      type: 'bbox'
      bbox: CameraBbox
    }
  | {type: 'position'; position: CameraPosition}

export type ClaimedLocationMapParcel = {
  parcel: Parcel
  claimedLocation: ClaimedLocation
}

export type MapParcel = {
  parcelCentroid?: GeoJSON.Point | null
  cherreParcelId: string | null
}

interface Props extends BoxProps {
  defaultCameraPosition?: CameraPosition
  cameraPosition?: CameraSetting
  selectedParcelId: string | null | undefined
  claimedParcels?: ClaimedLocationMapParcel[]
  filter: Filter
  onParcelSelected: (parcelId: string | null) => void
  isVisible: boolean
  overlays: AllOverlaysSettings
}

export function MapBoxMapV2({
  defaultCameraPosition = RALEIGH_CAMERA_POSITION,
  cameraPosition,
  selectedParcelId,
  claimedParcels,
  filter,
  onParcelSelected,
  isVisible,
  overlays,
}: Props) {
  const publicToken = React.useRef(
    document.querySelector<HTMLMetaElement>('meta[name="mapbox"]')?.content,
  )
  const mapRef = React.useRef<MapRef | null>(null)

  const [viewState, setViewState] = React.useState(defaultCameraPosition)
  const [source, setSource] = React.useState<string | null>(null)
  const [demographicOverlayRecord, setDemographicOverlayRecord] =
    React.useState<DemographicOverlayRecord | null>(null)
  const [realEstateOverlayRecord, setRealEstateOverlayRecord] =
    React.useState<RealEstateOverlayRecord | null>(null)

  const theme = useTheme()

  const {watchlistParcels} = useWatchlistParcels()

  const {
    request: fetchSource,
    response: data,
    loading,
    // TODO: handle data errors more gracefully
  } = useRequest<GeoJSON.FeatureCollection, SourceRequestPayload>(
    'POST',
    apiCoPilotParcelsFilterPath(),
  )

  // TODO: applying filter can cause memory leak (updating state of unmounted)
  /**
   * triggers:
   *   - first filter application
   *   - share_id=xxxx applying filter that removed current parcel from result
   */
  const updateSource = React.useCallback(
    (payload: SourceRequestPayload) => {
      // completely unmount the existing source before fetching new data
      const sourceId = payloadToSourceId(payload)
      setSource(null)
      fetchSource({data: payload}).then(() => setSource(sourceId))
    },
    [setSource, fetchSource],
  )

  /**
   * Avoid using useEffect and mapRef as much as possible. Default to using
   * explicit react state to trigger re-renders in react-map-gl components.
   * Only edge cases like calling map methods like flyTo should be pulled out
   * into useEffect for "uncontrolled" use
   */

  // update source data on filter change
  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }
    const map = mapRef.current
    const box = map.getBounds()
    if (!box) {
      console.log('Error: No bounding box on fetching data on filter change')
      return
    }
    const payload = getSourcePayload(box, filter)
    updateSource(payload)
  }, [filter, updateSource])

  // Fly to parcel location when one is manually searched for
  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }
    const map = mapRef.current
    if (cameraPosition) {
      if (cameraPosition.type === 'position') {
        map.flyTo({
          center: [
            cameraPosition.position.longitude,
            cameraPosition.position.latitude,
          ],
          zoom: cameraPosition.position.zoom,
        })
      } else if (cameraPosition.type === 'bbox') {
        map.fitBounds(cameraPosition.bbox)
      }
    }
  }, [cameraPosition])

  // Resize when nav'd to in case parent container sizes changed
  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }
    if (isVisible) {
      mapRef.current.resize()
    }
  }, [isVisible])

  const onLoad = (event: MapEvent) => {
    const map = event.target
    map.on('mouseenter', PARCEL_LAYER_ID, () => {
      map.getCanvas().style.cursor = 'pointer'
    })

    map.on('mouseleave', PARCEL_LAYER_ID, () => {
      map.getCanvas().style.cursor = ''
    })
    if (
      !map.hasImage('plain-pill') &&
      !map.hasImage('target-pill') &&
      !map.hasImage('portfolio-pill') &&
      !map.hasImage('claimed-castle')
    ) {
      // TODO: can probably turn these into proper react components
      const plainPill = new Image()
      plainPill.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
        <svg width="62" height="22" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="58" height="20" rx="14"
                fill="#FFFFFF" stroke="${PLAIN_PILL_COLOR}" stroke-width="1" />
        </svg>
      `)}`
      plainPill.onload = () => {
        map.addImage('plain-pill', plainPill)
      }
      const targetPill = new Image()
      targetPill.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
        <svg width="62" height="22" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="58" height="20" rx="14"
                fill="#FFFFFF"
                stroke="${theme.palette.primary.main}"
                stroke-width="2"/>
        </svg>
      `)}`
      targetPill.onload = () => {
        map.addImage('target-pill', targetPill)
      }
      const portfolioPill = new Image()
      portfolioPill.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
        <svg width="62" height="22" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="58" height="20" rx="14"
                fill="${theme.palette.primary.main}"
                stroke="${theme.palette.primary.main}"
                stroke-width="2"/>
        </svg>
      `)}`
      portfolioPill.onload = () => {
        map.addImage('portfolio-pill', portfolioPill)
      }
      const claimedCastle = new Image()
      claimedCastle.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
        <svg width="62" height="36" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="8" width="58" height="20" rx="14"
                fill="#FFFFFF"
                stroke="${PLAIN_PILL_COLOR}"
                stroke-width="2"/>
          <!-- Castle crenellations -->
          <path d="M15 8 L15 2 L20 2 L20 8
                  M24 8 L24 2 L29 2 L29 8
                  M33 8 L33 2 L38 2 L38 8
                  M42 8 L42 2 L47 2 L47 8"
                fill="#FFFFFF"
                stroke="${PLAIN_PILL_COLOR}"
                stroke-width="2"
                stroke-linejoin="round"/>
        </svg>
      `)}`
      claimedCastle.onload = () => {
        map.addImage('claimed-castle', claimedCastle)
      }
      // TODO: add portfolio castle for claimed ownership
      // TODO: add targets castle for targetting a claimed location
    }
    // construct overlay color schemes
    if (demographicOverlayRecord === null) {
      const newRecord = initializeDemographicOverlayRecord(map, STEPS)
      setDemographicOverlayRecord(newRecord)
    }
    if (realEstateOverlayRecord === null) {
      const newRecord = initializeRealEstateOverlayRecord(map, STEPS)
      setRealEstateOverlayRecord(newRecord)
    }
    // initialize first set of data
    const box = map.getBounds()
    if (!box) {
      console.log('Error: No bounding box on load')
      return
    }
    const payload = getSourcePayload(box, filter)
    updateSource(payload)
  }

  const onClick = React.useCallback(
    (event: MapMouseEvent) => {
      const map = event.target
      if (!event.features || event.features.length === 0) {
        // click on nothing revelant resets parcel selection
        onParcelSelected(null)
        return
      }

      const relevantFeatures = event.features.filter(
        (f) => !f.layer?.id.endsWith('-counts'),
      )

      const parcelSelected = relevantFeatures.filter(
        (f) => f.layer?.id === PARCEL_LAYER_ID,
      )[0]
      const pointSelected = relevantFeatures.filter((f) =>
        f.layer?.id.endsWith('-unclustered'),
      )[0]
      const clusterSelected = relevantFeatures.filter((f) =>
        f.layer?.id.endsWith('-clusters'),
      )[0]

      if (pointSelected || parcelSelected) {
        const feature = pointSelected ?? parcelSelected
        onParcelSelected(
          // parcels use snake_case but points use camelCase
          feature.properties?.cherre_parcel_id ??
            feature.properties?.cherreParcelId,
        )
        return
      }

      if (clusterSelected) {
        const feature = clusterSelected
        const mapboxSource = map.getSource<GeoJSONSource>(feature.source)

        if (!mapboxSource) {
          console.log('Error: No source for clicked feature')
          return
        }

        const clusterId = feature.properties?.cluster_id
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            console.log('Error in getting cluster zoom expansion')
            return
          }

          map.easeTo({
            // TODO: types here is whack
            center: (
              feature.geometry as Exclude<
                GeoJSON.Geometry,
                GeoJSON.GeometryCollection
              >
            ).coordinates as [number, number],
            zoom: zoom ?? undefined,
            duration: 500,
          })
        })
        return
      }

      // click on nothing revelant resets parcel selection
      onParcelSelected(null)
    },
    [onParcelSelected],
  )

  const onMouseEnter = (event: MapMouseEvent) => {
    const map = event.target
    if (!event.features || event.features.length === 0) {
      return
    }

    const relevantFeatures = event.features.filter(
      (f) =>
        f.layer?.id.endsWith('-clusters') ||
        f.layer?.id.endsWith('-unclustered'),
    )

    if (relevantFeatures.length > 0) {
      map.getCanvas().style.cursor = 'pointer'
    }
  }

  const onMouseLeave = (event: MapMouseEvent) => {
    const map = event.target
    if (!event.features || event.features.length === 0) {
      return
    }

    const relevantFeatures = event.features.filter(
      (f) =>
        f.layer?.id.endsWith('-clusters') ||
        f.layer?.id.endsWith('-unclustered'),
    )

    if (relevantFeatures.length > 0) {
      map.getCanvas().style.cursor = ''
    }
  }

  const onMoveEnd = React.useCallback(
    (event: ViewStateChangeEvent) => {
      const map = event.target
      // do nothing if no data is yet loaded
      if (!source) {
        return
      }

      // update loaded parcels (if needed)
      const bounds = map.getBounds()
      if (!bounds) {
        console.log(
          'Error: Map has no bounds when checking if a new source is needed',
        )
        return
      }
      if (source) {
        const rawBoundingBox = sourceIdToPayload(source).filter.boundingBox
        const lastBoundingBox = bboxPolygon([
          rawBoundingBox.bottomLeft.long,
          rawBoundingBox.bottomLeft.lat,
          rawBoundingBox.topRight.long,
          rawBoundingBox.topRight.lat,
        ])

        const currentBoundingBox = bboxPolygon([
          bounds.getSouthWest().lng,
          bounds.getSouthWest().lat,
          bounds.getNorthEast().lng,
          bounds.getNorthEast().lat,
        ])

        if (booleanContains(lastBoundingBox, currentBoundingBox)) {
          // we have all the data we need
          return
        }
      }
      const newPayload = getSourcePayload(bounds, filter)
      updateSource(newPayload)
    },
    [source, updateSource, filter],
  )

  const parcelPaint = React.useMemo(() => {
    const isHighlightedParcelClaimed = claimedParcels?.some(
      (p) => p.parcel.cherreParcelId === selectedParcelId,
    )
    return {
      'fill-color': [
        'case',
        [
          '==',
          ['get', 'cherre_parcel_id'],
          isHighlightedParcelClaimed ? selectedParcelId ?? null : null,
        ],
        '#D7EFFE',
        ['==', ['get', 'cherre_parcel_id'], selectedParcelId ?? null],
        theme.palette.primary.main,
        'transparent',
      ] as ExpressionSpecification,
    }
  }, [claimedParcels, selectedParcelId, theme])

  const {neighborhoodPaint, neighborhoodLayout} = React.useMemo(() => {
    const zipsToShow = filter.neighborhoodList
    if (zipsToShow === undefined || zipsToShow.length === 0) {
      return {
        neighborhoodPaint: {},
        neighborhoodLayout: {visibility: 'none'} as const,
      }
    }
    return {
      neighborhoodPaint: {
        'line-opacity': [
          'match',
          ['get', 'ZCTA5CE20'],
          zipsToShow,
          1,
          0,
        ] as ExpressionSpecification,
      },
      neighborhoodLayout: {visibility: 'visible'} as const,
    }
  }, [filter])

  const {
    clusterLayerProps,
    countsLayerProps,
    unclusteredLayerProps,
    targetsLayerProps,
    portfolioLayerProps,
    claimedLayerProps,
  } = React.useMemo(() => {
    if (!source) {
      return {
        clusterLayerProps: null,
        countsLayerProps: null,
        unclusteredLayerProps: null,
        targetsLayerProps: null,
        portfolioLayerProps: null,
        claimedLayerProps: null,
      }
    }
    const targetIds = watchlistParcels.map((p) => p.taxAssessorId)
    const claimedIds = claimedParcels
      ? claimedParcels.map((p) => p.parcel.taxAssessorId)
      : []
    const portfolioIds = claimedParcels
      ? claimedParcels
          .filter((cp) => cp.claimedLocation.claimedByCurrentUser)
          .map((cp) => cp.parcel.taxAssessorId)
      : []
    return {
      clusterLayerProps: clusterLayer(source, PLAIN_PILL_COLOR),
      countsLayerProps: countsLayer(source, '#000000'),
      unclusteredLayerProps: unclusteredLayer(
        source,
        '#000000',
        claimedIds.concat(targetIds).concat(portfolioIds),
      ),
      targetsLayerProps: targetsLayer(
        source,
        theme.palette.primary.main,
        watchlistParcels.map((p) => p.taxAssessorId),
      ),
      portfolioLayerProps: portfolioLayer(source, '#FFFFFF', portfolioIds),
      claimedLayerProps: claimedLayer(
        source,
        '#000000',
        claimedIds.filter((id) => !portfolioIds.includes(id)),
      ),
    }
  }, [source, theme, watchlistParcels, claimedParcels])

  const interactiveLayers = React.useMemo(() => {
    return source && data
      ? [
          PARCEL_LAYER_ID,
          `${source}-clusters`,
          `${source}-counts`,
          `${source}-unclustered`,
          `${source}-targets-unclustered`,
          `${source}-claimed-unclustered`,
          `${source}-owned-unclustered`,
        ]
      : [PARCEL_LAYER_ID]
  }, [source, data])

  return (
    <Box height="100%" width="100%" position="relative">
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 50,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Map
        {...viewState}
        ref={mapRef}
        initialViewState={defaultCameraPosition}
        mapLib={window.mapboxgl}
        mapboxAccessToken={publicToken.current}
        mapStyle={STYLE_URL}
        fadeDuration={0}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMove={(event) => setViewState(event.viewState)}
        onMoveEnd={onMoveEnd}
        onLoad={onLoad}
        interactiveLayerIds={interactiveLayers}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Layer
          key={PARCEL_LAYER_ID}
          id={PARCEL_LAYER_ID}
          type="fill"
          paint={parcelPaint}
        />
        <Layer
          key={NEIGHBORHOOD_LAYER_ID}
          id={NEIGHBORHOOD_LAYER_ID}
          type="line"
          layout={neighborhoodLayout}
          paint={neighborhoodPaint}
        />
        {demographicOverlayRecord &&
          overlays.activeOverlay.type === 'demographic' && (
            <DemographicOverlay
              overlay={overlays.activeOverlay.overlay}
              overlayRecord={demographicOverlayRecord}
            />
          )}
        {realEstateOverlayRecord &&
          overlays.activeOverlay.type === 'realEstate' && (
            <RealEstateOverlay
              overlay={overlays.activeOverlay.overlay}
              overlayRecord={realEstateOverlayRecord}
            />
          )}
        <TrafficLayer active={overlays.traffic} mapRef={mapRef} />
        <SatelliteLayer active={overlays.satellite} mapRef={mapRef} />
        {source &&
        data &&
        clusterLayerProps &&
        countsLayerProps &&
        unclusteredLayerProps &&
        targetsLayerProps ? (
          <Source
            key={source}
            id={source}
            type="geojson"
            data={data}
            cluster={true}
            clusterMaxZoom={13}
            clusterRadius={50}
          >
            <Layer key={`${source}-targets`} {...targetsLayerProps} />
            <Layer key={`${source}-portfolio`} {...portfolioLayerProps} />
            <Layer key={`${source}-claimed`} {...claimedLayerProps} />
            <Layer key={`${source}-clusters`} {...clusterLayerProps} />
            <Layer key={`${source}-counts`} {...countsLayerProps} />
            <Layer key={`${source}-unclustered`} {...unclusteredLayerProps} />
          </Source>
        ) : null}
      </Map>
      {overlays.activeOverlay.type !== 'none' &&
      demographicOverlayRecord &&
      realEstateOverlayRecord ? (
        <LegendContainer>
          <OverlayLegend
            activeOverlay={overlays.activeOverlay}
            demographicOverlayRecord={demographicOverlayRecord}
            realEstateOverlayRecord={realEstateOverlayRecord}
          />
        </LegendContainer>
      ) : null}
      {overlays.traffic ? (
        <LegendContainer right={true}>
          <TrafficLegend />
        </LegendContainer>
      ) : null}
    </Box>
  )
}

function LegendContainer({
  children,
  right,
}: {
  children: JSX.Element
  right?: boolean
}) {
  return (
    <Box
      padding={1}
      maxWidth="50%"
      maxHeight="40%"
      sx={{
        position: 'absolute',
        bottom: '4%',
        left: right ? undefined : '3%',
        right: right ? '3%' : undefined,
        zIndex: 2,
        backgroundColor: '#FFFFFF',
        border: '2px solid black',
      }}
    >
      {children}
    </Box>
  )
}
