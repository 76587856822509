import * as React from 'react'

import {Divider, Typography, Button} from '@mui/material'
import {Box} from '@mui/material'
import {Stack} from '@mui/system'
import {capitalize} from 'lodash'

import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {Parcel} from 'src/types/copilot'
import {formatCurrency, formatDecimal} from 'src/util/format'
import {formatAddress} from 'src/util/format/formatAddress'

interface Props {
  parcel: Parcel
  handleViewSiteSurvey?: () => void
}

type ListingStatus = Parcel['listingStatus']
type PropensityToSell = Parcel['propensityToSell']

const StatusLabel = ({status}: {status: ListingStatus}) => {
  const getConfig = (status: ListingStatus) => {
    switch (status) {
      case 'for_sale':
        return {
          label: 'For sale',
          color: '#4CAF50', // green
        }
      case 'for_lease':
        return {
          label: 'For lease',
          color: '#2196F3', // blue
        }
      case 'off_market':
        return {
          label: 'Off market',
          color: '#FFC107', // yellow
        }
      default:
        return {
          label: 'Off market',
          color: '#FFC107', // yellow
        }
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '4px 10px',
        borderRadius: '20px',
        backgroundColor: 'rgba(239, 242, 246, 1)',
        maxWidth: 'fit-content',
      }}
    >
      <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: getConfig(status).color,
          marginRight: 1,
        }}
      />
      <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
          color: '#333',
        }}
      >
        {getConfig(status).label}
      </Typography>
    </Box>
  )
}

const PropensityToSellLabel = ({
  propensity,
}: {
  propensity: PropensityToSell
}) => {
  const getConfig = (propensity: PropensityToSell) => {
    switch (propensity) {
      case 'HIGH':
        return {
          label: 'Likely to sell',
          color: '#4CAF50', // green
        }
      case 'MEDIUM':
        return {
          label: 'Likely to sell',
          color: '#FFC107', // yellow
        }
      default:
        return null
    }
  }

  const config = getConfig(propensity)
  if (!config) return null

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '4px 10px',
        borderRadius: '20px',
        backgroundColor: 'rgba(239, 242, 246, 1)',
        maxWidth: 'fit-content',
      }}
    >
      <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: config.color,
          marginRight: 1,
        }}
      />
      <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
          color: '#333',
        }}
      >
        {config.label}
      </Typography>
    </Box>
  )
}

function SiteSurveyButton({
  handleViewSiteSurvey,
}: {
  handleViewSiteSurvey: () => void
}) {
  return (
    <Button
      variant="text"
      disableElevation
      onClick={handleViewSiteSurvey}
      startIcon={
        <MaterialSymbolIcon color="info">square_foot</MaterialSymbolIcon>
      }
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '20px',
        backgroundColor: 'rgba(239, 242, 246, 1)',
        maxWidth: 'fit-content',
        padding: '4px 10px',
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
          color: '#333',
        }}
      >
        Site Survey
      </Typography>
    </Button>
  )
}

export const ParcelHighlights = ({
  parcel,
  handleViewSiteSurvey,
}: Props): JSX.Element => {
  return (
    <>
      <Stack spacing={0.5}>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Stack direction="row" gap={1}>
            <StatusLabel status={parcel.listingStatus} />
            <PropensityToSellLabel propensity={parcel.propensityToSell} />
          </Stack>
          {handleViewSiteSurvey ? (
            <SiteSurveyButton handleViewSiteSurvey={handleViewSiteSurvey} />
          ) : null}
        </Stack>
        <Stack
          display="flex"
          flexDirection={'row'}
          gap={1}
          alignItems={'flex-end'}
        >
          <Typography variant="h4">
            {formatCurrency(parcel.salesPrice?.toString(), {
              precision: 0,
            })}
          </Typography>
          <Typography
            variant="body1"
            color="secondary"
            mb={0.25}
            sx={{fontSize: '.75rem'}}
          >
            {parcel.salesPriceSource == 'Listing' ? 'asking price' : 'estimate'}
            {parcel.salesPriceSource == 'Estimate' &&
            parcel.listingStatus == 'for_sale'
              ? ' (asking price not available)'
              : null}
          </Typography>
        </Stack>
        <Typography>
          {formatAddress(parcel.address, parcel.city, parcel.state, null)}
        </Typography>
        <Stack
          direction="row"
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{borderColor: '#000000'}}
            />
          }
          alignItems="center"
          spacing={1}
        >
          <Typography>{capitalize(parcel.propertyType || '')}</Typography>
          <Typography>
            {formatDecimal(parcel.buildingSqFt, {precision: 0, suffix: 'sqft'})}
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}
